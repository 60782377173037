<template lang="html">
  <div :class="{ tabs__light: mode === 'light', tabs__dark: mode === 'dark' }">
    <b-row class="submenu-row">
      <ul class="tabs__header">
        <!-- LIVESTREAM TAB -->
        <Tab
          v-on:click.native="selectTab('livestream')"
          :class="{ tab__selected: 'livestream' == selectedTab }"
          :title="this.$i18n.t('livestream')"
          icon="video"
        >
        </Tab>
        <ChatQR
          v-if="livestream.type[locale] !== 'whereby' && chatActive && false"
          class="qr-button push-right"
          :currentUser="currentUser"
          :livestream="livestream"
        />
      </ul>
    </b-row>
    <!-- LIVESTREAM CONTENT -->
    <div class="main" v-if="selectedTab == 'livestream'">
      <Speakers
        v-if="livestream.speakers.active"
        class="speakers-component"
        :class="{ 'speakers-collapsed': speakerCollapsed }"
        :speakers="livestream.speakers[locale]"
        @collapse="onCollapse"
      />
      <b-row class="livestream-row">
        <Video
          :class="{
            'col-lg-9': chatActive,
            'col-lg-12': !chatActive,
          }"
          :id="livestream.id"
        />
        <Chat
          class="col-lg-3"
          :type="chatType"
          :code="chatCode"
          :roomCode="chatRoomCode"
          v-if="chatActive"
        />
      </b-row>
      <b-row class="mb-5">
        <ScheduleTimeline :selected="livestream.schedule.selected" />
        <VideoInfo :livestream="livestream" />
      </b-row>
      <b-row class="mb-5 livestream-content">
        <b-col cols="12" md="12" xl="12">
          <Items :blocks="livestreamBlocks" />
        </b-col>
      </b-row>
    </div>
    <!-- INTERACTIVE CONTENT -->
    <div class="main" v-if="selectedTab == 'interactive'">
      <Whereby :currentUser="currentUser" v-if="whereby.active"></Whereby>
    </div>
  </div>
</template>

<script>
import Tab from "@/components/tabs/Tab";
import Video from "@/components/video/Video";
import VideoInfo from "@/components/video/VideoInfo";
import Chat from "@/components/chat/Chat";
import ChatQR from "@/components/chat/ChatQR";
import Whereby from "@/components/interactive-livestream/Whereby.vue";
// import Attachments from "@/components/attachments/Attachments";
import Items from "@/components/landing/Items.vue";
import ScheduleTimeline from "@/components/schedule/ScheduleTimeline.vue";
import Speakers from "@/components/speakers/Speakers.vue";

export default {
  components: {
    Tab,
    Video,
    VideoInfo,
    Chat,
    Whereby,
    // Attachments,
    ChatQR,
    Items,
    ScheduleTimeline,
    Speakers,
  },
  props: {
    mode: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      selectedTab: "livestream", // the index of the selected tab,
      speakerCollapsed: false,
    };
  },
  async created() {
    this.fetchData();
  },
  watch: {
    "$route.query.id": {
      handler: () => {
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    settings() {
      return this.$store.state.Config.all;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    livestream() {
      if (this.$route.query.id) {
        return this.$store.getters["Livestreams/livestream"];
      } else {
        return this.$store.getters["Livestreams/active"];
      }
    },
    whereby() {
      return this.$store.state.Whereby.all;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    chat() {
      return this.livestream?.chat;
    },
    attachments() {
      return this.$store.state.Attachments.attachments;
    },
    chatActive() {
      return this.chat?.active;
    },
    chatType() {
      return this.chat?.type[this.locale];
    },
    chatCode() {
      return this.chat?.code[this.locale];
    },
    chatRoomCode() {
      return this.chat?.roomCode[this.locale];
    },
    livestreamBlocks() {
      return this.livestream?.blocks;
    },
  },
  methods: {
    async fetchData() {
      if (this.$route.query.id) {
        await this.$store.dispatch(
          "Livestreams/fetchById",
          this.$route.query.id
        );
      } else {
        await this.$store.dispatch("Livestreams/fetchAll");
      }
      this.selectTab("livestream");
      await this.$store.dispatch("Whereby/fetchAll");
    },
    onCollapse(value) {
      this.speakerCollapsed = value;
    },
    selectTab(selected) {
      this.selectedTab = selected;
    },
    StopAllAudio() {
      var iframe = this.$el.querySelector("iframe");
      var video = this.$el.querySelector("video");
      if (iframe) {
        var iframeSrc = iframe.src;
        iframe.src = iframeSrc;
      }
      if (video) {
        video.pause();
      }
    },
  },
};
</script>

<style lang="scss">
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);

.push-right {
  float: right;
  margin-left: auto;
  right: 0;
}

.tabs__light li {
  color: #fff;
}

.tabs__light li.tab__selected {
  background-color: #fff;
  color: #000;
}

.tabs__dark .tab {
  background-color: #555;
  color: #eee;
}

.tabs__dark li {
  background-color: #ddd;
  color: #aaa;
}

.tabs__dark li.tab__selected {
  background-color: #555;
  color: white;
}

.main {
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  padding: 0.6923em 2.3077em;
  padding-top: 25px;
  padding-bottom: 0;

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.livestream-row {
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgb(0 0 0 / 40%);
}

ul.tabs__header {
  display: flex;
  // align-items: center;
  padding: 0 2.3077em;
  list-style: none;
  margin-left: 0px;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;

  // padding-left: 68px;
  // padding-right: 68px;
}

.submenu-row {
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: $color-secondary;
}

.livestream-content {
  .col-12 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.speakers-component {
  z-index: 1;
  position: fixed;
  width: 60px;
  height: 390px;
  left: 0;
  transition: all 0.5s ease;
}

.speakers-collapsed {
  width: 400px;
}

.row [class*="col-"] {
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 999px) {
  .main {
    padding-top: 15px;
    padding-left: 35px;
    padding-right: 35px;
    margin: 0 0;
  }
}

@media only screen and (max-width: 750px) {
  .qr-button {
    display: none;
  }
  .nav {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 556px) {
  .tab-title {
    display: none;
  }

  .nav-tabs,
  ul.tabs__header {
    align-items: center;
    justify-content: center;
  }

  .main {
    padding-top: 15px;
  }
}
</style>
